<template>
  <v-dialog
    v-model="dialog"
    persistent
    width="1200"
    @click:outside="clickOutside"
  >
    <template v-slot:activator="{ attrs }">
      <p id="payreqForm" v-bind="attrs" @click="openForm"></p>
    </template>
    <v-card>
      <v-card-title>
        <span class="text-h5" style="margin: auto"
          >FORMULIR PAYMENT REQUEST</span
        >
      </v-card-title>
      <v-form
        v-if="form != null"
        ref="formPayreq"
        :disabled="loading"
        lazy-validation
        @submit.prevent="saveForm"
        style="position: relative"
      >
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="3" class="d-flex flex-column">
                <p
                  class="text-left"
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Pemohon
                </p>

                <v-text-field
                  :value="form.employee.name"
                  disabled
                  dense
                  outlined
                  style="font-size: 12px; height: 45px"
                />
              </v-col>
              <v-col cols="12" md="3" class="d-flex flex-column">
                <p
                  class="text-left"
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Perusahaan
                </p>

                <v-text-field
                  :value="form.company.name"
                  disabled
                  dense
                  outlined
                  style="font-size: 12px; height: 45px"
                />
                <!-- <v-autocomplete
                  v-model="form.company"
                  :items="dropdown.company"
                  item-text="name"
                  item-value="id"
                  style="font-size: 12px; height: 45px"
                  return-object
                  dense
                  outlined
                  clearable
                  disabled
                ></v-autocomplete> -->
              </v-col>
              <v-col cols="12" md="3" class="d-flex flex-column">
                <p
                  class="text-left"
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Department
                </p>

                <v-text-field
                  :value="form.department.name"
                  disabled
                  dense
                  outlined
                  style="font-size: 12px; height: 45px"
                />
                <!-- <v-autocomplete
                  v-model="form.department"
                  :items="dropdown.department"
                  style="font-size: 12px; height: 45px"
                  item-text="name"
                  item-value="id"
                  return-object
                  dense
                  outlined
                  clearable
                  disabled
                ></v-autocomplete> -->
              </v-col>
              <v-col md="3" class="d-flex flex-column"></v-col>

              <v-col cols="12" md="3" class="d-flex flex-column">
                <p
                  class="text-left"
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Jenis Payment Request
                </p>

                <v-autocomplete
                  v-model="form.payreq_type"
                  :items="dropdown.payreq_type"
                  :rules="rules.payreq_type"
                  item-text="name"
                  item-value="id"
                  style="font-size: 12px; height: 45px"
                  return-id
                  dense
                  outlined
                  clearable
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="9" class="d-flex flex-column">
                <p
                  class="text-left"
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Pilih Vendor / Dibayarkan Kepada
                </p>
                <div
                  class="d-flex"
                  style="margin: 0; padding: 0; position: relative"
                >
                  <v-radio-group
                    v-model="form.vendor_type"
                    mandatory
                    :column="false"
                    class="mr-5"
                    style="height: 35px; position: relative; top: -10px"
                  >
                    <div class="mr-3 d-flex">
                      <v-radio value="1"></v-radio>
                      <p style="margin: 0; font-size: 12px">Dari eCatalogue</p>
                    </div>
                    <div class="d-flex">
                      <v-radio value="0"></v-radio>
                      <p style="margin: 0; font-size: 12px">Tulis Manual</p>
                    </div>
                  </v-radio-group>

                  <v-autocomplete
                    v-if="form.vendor_type == 1"
                    v-model="form.vendor"
                    :items="dropdown.vendor"
                    :search-input.sync="searchVendor"
                    :rules="rules.vendor"
                    item-text="name"
                    item-value="code"
                    placeholder="Cari nama vendor"
                    style="font-size: 12px; height: 45px"
                    return-object
                    dense
                    outlined
                    clearable
                  ></v-autocomplete>
                  <v-text-field
                    v-if="form.vendor_type == 0"
                    v-model="form.vendor_name"
                    :rules="rules.vendor_name"
                    dense
                    outlined
                    placeholder="Ketik nama vendor"
                    style="font-size: 12px; height: 45px"
                  />
                </div>
              </v-col>

              <v-col cols="12" md="3" class="d-flex flex-column">
                <p
                  class="text-left"
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Jenis Pembayaran
                </p>

                <v-autocomplete
                  v-model="form.payment_type"
                  :items="dropdown.payment_type"
                  :rules="rules.payment_type"
                  item-text="name"
                  item-value="id"
                  style="font-size: 12px; height: 45px"
                  return-id
                  dense
                  outlined
                  clearable
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="3" class="d-flex flex-column">
                <div
                  class="d-flex flex-column"
                  v-if="form.payment_type != 1 && form.payment_type != null"
                >
                  <p
                    class="text-left"
                    style="
                      margin: 0;
                      font-size: 12px;
                      font-weight: bold;
                      color: rgba(0, 0, 0, 0.5);
                    "
                  >
                    Nama Bank
                  </p>

                  <v-text-field
                    v-model="form.bank_name"
                    :rules="rules.bank_name"
                    dense
                    outlined
                    style="font-size: 12px; height: 45px"
                  />
                </div>
              </v-col>
              <v-col cols="12" md="3" class="d-flex flex-column">
                <div
                  class="d-flex flex-column"
                  v-if="form.payment_type != 1 && form.payment_type != null"
                >
                  <p
                    class="text-left"
                    style="
                      margin: 0;
                      font-size: 12px;
                      font-weight: bold;
                      color: rgba(0, 0, 0, 0.5);
                    "
                  >
                    No. Rekening
                  </p>

                  <v-text-field
                    v-model="form.account_number"
                    :rules="rules.account_number"
                    dense
                    outlined
                    style="font-size: 12px; height: 45px"
                  />
                </div>
              </v-col>
              <v-col cols="12" md="3" class="d-flex flex-column">
                <div
                  class="d-flex flex-column"
                  v-if="form.payment_type != 1 && form.payment_type != null"
                >
                  <p
                    class="text-left"
                    style="
                      margin: 0;
                      font-size: 12px;
                      font-weight: bold;
                      color: rgba(0, 0, 0, 0.5);
                    "
                  >
                    Atas nama
                  </p>

                  <v-text-field
                    v-model="form.account_name"
                    :rules="rules.account_name"
                    dense
                    outlined
                    style="font-size: 12px; height: 45px"
                  />
                </div>
              </v-col>

              <v-col cols="12" md="3" class="d-flex flex-column">
                <p
                  class="text-left"
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Sumber Dana
                </p>

                <v-text-field
                  v-model="form.source_of_funds"
                  dense
                  outlined
                  style="font-size: 12px; height: 45px"
                />
              </v-col>
              <v-col cols="12" md="9" class="d-flex flex-column">
                <p
                  class="text-left"
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Untuk Pembayaran
                </p>

                <v-text-field
                  v-model="form.payment_desc"
                  dense
                  outlined
                  style="font-size: 12px; height: 45px"
                />
              </v-col>

              <!-- <v-col cols="12" md="3" class="d-flex flex-column">
                <p
                  class="text-left"
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Pph
                </p>
                <v-text-field
                  v-model="form.pph"
                  dense
                  outlined
                  type="number"
                  min="0"
                  style="height: 45px"
                />
              </v-col>
              <v-col cols="12" md="3" class="d-flex flex-column">
                <p
                  class="text-left"
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Ppn
                </p>
                <v-text-field
                  v-model="form.ppn"
                  dense
                  outlined
                  type="number"
                  min="0"
                  style="height: 45px"
                />
              </v-col> -->
              <v-col cols="12" md="3" class="d-flex flex-column">
                <p
                  class="text-left"
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Disc. Global (%)
                </p>

                <v-text-field
                  v-model="form.global_discount_percent"
                  dense
                  outlined
                  type="number"
                  min="0"
                  style="font-size: 12px; height: 45px"
                  @change="globalPercentWatcher"
                />
              </v-col>
              <v-col cols="12" md="3" class="d-flex flex-column">
                <p
                  class="text-left"
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Disc. Global (nominal)
                </p>

                <v-text-field
                  v-model="form.global_discount"
                  @change="globalAmountWatcher"
                  dense
                  outlined
                  type="number"
                  min="0"
                  style="font-size: 12px; height: 45px"
                />
              </v-col>
            </v-row>

            <div style="margin-top: 20px">
              <v-data-table
                max-height="50vh"
                mobile-breakpoint="0"
                style="cursor: pointer; border: 1px solid rgba(0, 0, 0, 0.2)"
                :headers="payreqHeaders"
                :items="form.payment_request_detail"
                :items-per-page="10"
                item-key="id"
              >
                <template v-slot:[`item.no`]="{ index }">
                  <div style="width: 35px">
                    {{ index + 1 }}
                  </div>
                </template>
                <template v-slot:[`item.product_code`]="{ item }">
                  <div style="width: 95px; font-size: 12px">
                    {{ item.product_code }}
                  </div>
                </template>
                <template v-slot:[`item.product_name`]="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <div
                        v-bind="attrs"
                        v-on="on"
                        style="
                          text-align: left;
                          width: 250px;
                          font-size: 12px;
                          padding: 0;
                        "
                      >
                        {{
                          `${
                            item.product_code !== null
                              ? '(' + item.product_code + ')'
                              : ''
                          } ${item.product_name}`
                        }}
                      </div>
                    </template>
                    <span>
                      {{
                        `${
                          item.product_code !== null
                            ? '(' + item.product_code + ')'
                            : ''
                        } ${item.product_name}`
                      }}</span
                    >
                  </v-tooltip>
                </template>
                <template v-slot:[`item.qty_demand`]="{ item }">
                  <div style="width: 65px; font-size: 12px">
                    {{ item.qty_demand }}
                  </div>
                </template>
                <template v-slot:[`item.qty_paid`]="{ item }">
                  <div style="width: 65px; font-size: 12px">
                    {{ item.qty_paid }}
                  </div>
                </template>
                <template v-slot:[`item.qty_request`]="{ item }">
                  <div
                    style="
                      width: 95px;
                      height: 45px;
                      font-size: 12px;
                      margin-top: 3px;
                    "
                  >
                    <v-text-field
                      v-model="item.qty_request"
                      type="number"
                      :min="0"
                      :max="item.qty_demand - item.qty_paid"
                      dense
                      outlined
                      style="margin: 0"
                    />
                  </div>
                </template>
                <template v-slot:[`item.uom`]="{ item }">
                  <div style="width: 45px; font-size: 12px">
                    {{ item.uom_name }}
                  </div>
                </template>
                <template v-slot:[`item.price_request`]="{ item }">
                  <div
                    style="
                      width: 135px;
                      height: 45px;
                      font-size: 12px;
                      margin-top: 3px;
                    "
                  >
                    <v-text-field
                      v-model="item.price_request"
                      type="number"
                      dense
                      outlined
                      style="margin: 0"
                    />
                  </div>
                </template>
                <template v-slot:[`item.discount_percent`]="{ item }">
                  <div
                    style="
                      width: 75px;
                      height: 45px;
                      font-size: 12px;
                      margin-top: 3px;
                    "
                  >
                    <v-text-field
                      v-model="item.discount_percent"
                      :disabled="
                        item.discount_amount != null &&
                        item.discount_amount != 0
                      "
                      type="number"
                      min="0"
                      dense
                      outlined
                      style="margin: 0"
                    />
                  </div>
                </template>
                <template v-slot:[`item.discount_amount`]="{ item }">
                  <div
                    style="
                      width: 135px;
                      height: 45px;
                      font-size: 12px;
                      margin-top: 3px;
                    "
                  >
                    <v-text-field
                      v-model="item.discount_amount"
                      :disabled="
                        item.discount_percent != null &&
                        item.discount_percent != 0
                      "
                      dense
                      type="number"
                      min="0"
                      outlined
                      style="margin: 0"
                    />
                  </div>
                </template>
                <template v-slot:[`item.tax_type`]="{ item }"
                  ><div
                    style="
                      width: 135px;
                      height: 45px;
                      font-size: 12px;
                      margin-top: 3px;
                    "
                  >
                    <v-autocomplete
                      v-model="item.tax_type"
                      :items="dropdown.tax"
                      item-text="name"
                      item-value="id"
                      style="font-size: 12px; height: 45px"
                      return-id
                      dense
                      outlined
                      clearable
                    ></v-autocomplete>
                  </div>
                </template>
                <!-- <template v-slot:[`item.price_total`]="{ item }">
                  <div style="width: 135; font-size: 12px">
                    {{ (item.qty_request * item.price) }}
                  </div>
                </template> -->
              </v-data-table>
            </div>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <div style="padding: 0 25px 20px; width: 100%">
            <v-btn
              type="submit"
              style="width: 100%"
              color="blue darken-1"
              class="white--text"
              :loading="loading"
            >
              SIMPAN
            </v-btn>
            <v-btn
              style="width: 100%; margin-top: 15px"
              color="pink"
              class="white--text"
              @click="closeDialog"
              :loading="loading"
            >
              BATAL
            </v-btn>
          </div>
          <!-- <v-btn color="blue darken-1" text type="submit"> Save </v-btn> -->
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import buildType from '../../../services/buildType'
export default {
  name: 'FormPayreq',
  props: ['detail', 'selectedData', 'dropdownUnit'],
  data() {
    return {
      proc: buildType.apiURL('proc'),
      loading: false,
      dialog: false,
      form: null,
      dropdown: {
        payment_type: [
          {
            id: 1,
            name: 'Tunai'
          },
          {
            id: 2,
            name: 'Bank'
          },
          {
            id: 3,
            name: 'Giro'
          },
          {
            id: 4,
            name: 'Cek'
          },
          {
            id: 5,
            name: 'Transfer'
          }
        ],
        payreq_type: [
          {
            id: 1,
            name: 'CA'
          },
          {
            id: 2,
            name: 'Reimburst'
          },
          {
            id: 3,
            name: 'Pelunasan PO'
          },
          {
            id: 4,
            name: 'Lain-lain'
          }
        ],
        vendor: [],
        tax: [
          {
            id: 3,
            name: 'PPn 11% Inc'
          },
          {
            id: 4,
            name: 'PPn 11% Exc'
          },
          {
            id: 5,
            name: 'PPh 22 (0,3%)'
          }
        ]
      },
      searchVendor: null,
      selectedVendor: null,
      rules: {
        payment_type: [],
        payreq_type: [],
        vendor: [],
        vendor_name: [],
        bank_name: [],
        account_name: [],
        account_number: []
      }
      // unwatchprice: [],
      // unwatchdiscountamount: [],
      // unwatchtax: []
    }
  },
  computed: {
    ...mapGetters(['getUserProfile']),
    payreqHeaders() {
      const arrHeaders = [
        {
          text: 'No',
          value: 'no',
          align: 'left'
          // sortable: true,
        },
        {
          text: 'Kode Barang',
          value: 'product_code',
          align: 'left'
          // sortable: false,
        },
        {
          text: 'Nama Barang',
          value: 'product_name',
          align: 'left'
          // sortable: false,
        },
        {
          text: 'Qty Fpb',
          value: 'qty_demand',
          align: 'left',
          // sortable: true,
          sortable: false
        },
        // {
        //   text: 'Harga FPB',
        //   value: 'fpb_price',
        //   align: 'left',
        //   sortable: false
        // },
        // {
        //   text: 'Unit',
        //   value: 'uom',
        //   align: 'left',
        //   sortable: false
        // },
        {
          text: 'Qty Dibayar',
          value: 'qty_paid',
          align: 'left',
          // sortable: true,
          sortable: false
        },
        {
          text: 'Qty Request',
          value: 'qty_request',
          align: 'left',
          // sortable: true,
          sortable: false
        },
        {
          text: 'Unit',
          value: 'uom',
          align: 'left',
          sortable: false
        },
        {
          text: 'Harga',
          value: 'price_request',
          align: 'left',
          sortable: false
        },
        {
          text: 'Disc (%)',
          value: 'discount_percent',
          align: 'left',
          // sortable: true,
          sortable: false
        },
        {
          text: 'Disc Amount',
          value: 'discount_amount',
          align: 'left',
          // sortable: true,
          sortable: false
        },
        {
          text: 'Tax',
          value: 'tax_type',
          align: 'left',
          // sortable: true,
          sortable: false
        },
        {
          text: 'Total',
          value: 'price_total',
          align: 'left',
          // sortable: true,
          sortable: false
        }
      ]
      // if (this.detail.status.id === 0) {
      return arrHeaders
      // }
    }
  },
  watch: {
    searchVendor(val) {
      if (val !== '') {
        val && val !== this.selectedVendor && this.querySelections(val)
      } else {
        this.selectedVendor = null
        this.dropdown.vendor = []
      }
    },
    'form.vendor_type'() {
      if (this.form != null) {
        this.form.vendor = null
        this.form.vendor_name = null
      }
    }
  },
  methods: {
    onResize() {
      this.wWidth = window.innerWidth
    },
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise((resolve) => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then((r) => {
            resolve(r)
          })
      })
    },
    querySelections(v) {
      const url = `${this.proc}vendor/dropdown?keyword=${v}&offset=0&limit=10&order_by=name&order_type=asc`
      // if (this.form.department !== null) {
      //   url = url + `&department_parent_id=${this.form.department.id}`
      // }
      axios
        .get(url)
        .then((res) => {
          // this.loading = false
          if (res.data.status_code === '00') {
            const resArr = []
            for (let i = 0; i < res.data.data.length; i++) {
              resArr.push({
                id: res.data.data[i].id,
                code: res.data.data[i].code,
                name: res.data.data[i].name
              })
            }
            return (this.dropdown.vendor = resArr)
          }
          return (this.dropdown.vendor = [])
        })
        .catch((err) => {
          this.dropdown.vendor = []
          return console.log(err)
        })
    },
    openForm() {
      console.log('hallo world', this.selectedData)
      const arrDetails = []
      const objForm = {
        act: 'add_batch_in_item',
        purchase_request_id: this.detail.id,
        employee: {
          id: this.detail.employee.id,
          name: this.detail.employee.name
        },
        company: {
          id: this.detail.company.id,
          name: this.detail.company.name,
          code: this.detail.company.code
        },
        department: {
          id: this.detail.department.id,
          name: this.detail.department.name
        },
        payment_type: null,
        payreq_type: null,
        vendor_type: 0,
        vendor: null,
        vendor_name: null,
        account_name: null,
        account_number: null,
        bank_name: null,
        source_of_funds: null,
        payment_desc: null,
        // ppn: null,
        // pph: null,
        global_discount: 0,
        global_discount_percent: 0
      }
      for (let i = 0; i < this.selectedData.length; i++) {
        arrDetails.push({
          no: i + 1,
          prd_id: this.selectedData[i].id,
          product_id: this.selectedData[i].product.id,
          product_name: this.selectedData[i].product.name,
          product_code: this.selectedData[i].product.code,
          qty_demand: this.selectedData[i].qty,
          // qty_left: this.selectedData[i].qty_left,
          qty_paid: this.selectedData[i].qty_paid,
          qty_request: this.selectedData[i].qty - this.selectedData[i].qty_paid,
          price_request: this.selectedData[i].budget_price_per_unit,
          price_demand: this.selectedData[i].budget_price_per_unit,
          uom_id: this.selectedData[i].uom_id,
          uom_name: this.selectedData[i].uom,
          discount_percent: 0,
          discount_amount: 0,
          tax_type: null,
          description: null
        })
      }

      Object.assign(objForm, {
        payment_request_detail: arrDetails
      })
      this.form = objForm
      setTimeout(() => {
        // this.startWatcher()
        this.dialog = true
      }, 500)
    },
    // clear() {
    //   this.$emit('loadPage')
    // },
    clickOutside() {
      //   this.closeDialog()
    },
    closeDialog() {
      this.dialog = false
      // if (this.form.payment_request_detail.length > 0) {
      //   for (let i = 0; i < this.form.payment_request_detail.length; i++) {
      //     this.unwatchprice[i]()
      //     this.unwatchdiscountamount[i]()
      //     this.unwatchtax[i]()
      //   }
      // }
      setTimeout(() => {
        this.form = null
        this.$emit('closeFormPayreq', 'close')
      }, 200)
    },
    saveForm() {
      this.rules.payreq_type = [(v) => !!v || 'Wajib diisi']
      this.rules.payment_type = [(v) => !!v || 'Wajib diisi']
      if (this.form.vendor_type == 1) {
        this.rules.vendor = [(v) => !!v || 'Wajib diisi']
      } else if (this.form.vendor_type == 0) {
        this.rules.vendor_name = [(v) => !!v || 'Wajib diisi']
      }

      if (this.form.payment_type != 1) {
        this.rules.bank_name = [(v) => !!v || 'Wajib diisi']
        this.rules.account_name = [(v) => !!v || 'Wajib diisi']
        this.rules.account_number = [(v) => !!v || 'Wajib diisi']
      }
      const self = this
      setTimeout(function () {
        if (self.$refs.formPayreq.validate()) {
          self.createNewForm()
        }
      })
    },
    createNewForm() {
      const newForm = {
        act: this.form.act,
        purchase_request_id: this.form.purchase_request_id,
        employee_id: this.form.employee.id,
        employee_name: this.form.employee.name,
        company_id: this.form.company.id,
        company_code: this.form.company.code,
        company_name: this.form.company.name,
        department_id: this.form.department.id,
        department_name: this.form.department.name,
        payment_type: this.form.payment_type,
        account_name: this.form.account_name,
        account_number: this.form.account_number,
        bank_name: this.form.bank_name,
        payreq_type: this.form.payreq_type,
        payment_desc: this.form.payment_desc,
        source_of_funds: this.form.source_of_funds,
        // ppn: this.form.ppn,
        // pph: this.form.pph,
        global_discount: this.form.global_discount,
        global_discount_percent: this.form.global_discount_percent,
        payment_request_detail: this.form.payment_request_detail
      }
      if (this.form.vendor_type == 1) {
        Object.assign(newForm, {
          vendor_id: this.form.vendor.id,
          vendor_code: this.form.vendor.code,
          vendor_name: this.form.vendor.name
        })
      } else {
        Object.assign(newForm, {
          vendor_id: null,
          vendor_code: null,
          vendor_name: this.form.vendor_name
        })
      }
      this.submitPayreq(newForm)
    },
    async submitPayreq(form) {
      this.loading = true
      await axios
        .post(`${this.proc}payreq/save`, form)
        .then((res) => {
          this.loading = false
          if (res.data.status_code == '00') {
            const created_id = res.data.created_id
            console.log(created_id)
            // this.showMsgDialog('success', res.data.status_msg, false)
            this.closeDialog()
            setTimeout(() => {
              // this.$store.commit('setFpbId', created_id)
              this.$router.push(
                `/e-catalogue/payreq/detail/${created_id}?idx=0`
              )
            }, 100)
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'warning',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('warning', res.data.status_msg, false)
            }
          }
        })
        .catch((err) => {
          console.log(err)
          this.loading = false
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
        })
    },

    globalAmountWatcher() {
      if (this.form != null) {
        if (
          this.form.global_discount != null &&
          this.form.global_discount != 0
        ) {
          this.form.global_discount_percent = 0
        }
      }
      return 0
    },
    globalPercentWatcher() {
      if (this.form != null) {
        if (
          this.form.global_discount_percent != null &&
          this.form.global_discount_percent != 0
        ) {
          this.form.global_discount = 0
        }
      }
      return 0
    }
  }
}
</script>
